<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select
					size="small"
					v-if="isAdmin"
					@change="agentChange"
					v-model="query.agentId"
					filterable
					clearable
					placeholder="代理商"
					class="handle-select mr10"
					style="width: 260px"
				>
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					style="width: 260px"
					v-if="isAgent"
					v-model="query.companyId"
					@change="companyChange"
					filterable
					clearable
					placeholder="企业"
					class="handle-select mr10"
				>
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					v-model="query.departmentId"
					@change="departmentChange"
					filterable
					clearable
					placeholder="部门"
					class="handle-select mr10"
					style="width: 180px"
				>
					<el-option v-for="item in departments" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.userId" filterable clearable placeholder="员工" class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>

				<el-select size="small" v-model="query.sortField" filterable clearable placeholder="请选择排序数据" class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in sortFieldList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.sortBy" filterable clearable placeholder="请选择排序方向" class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in sortByList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-date-picker
					size="small"
					v-model="value1"
					type="daterange"
					unlink-panels
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				></el-date-picker>
				<el-button size="small" type="primary" icon="el-icon-search" style="margin-left: 10px;" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" icon="el-icon-upload2" style="margin-top: 10px;" @click="handleDownload">导出</el-button>
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				v-loading="loading"
				:row-style="{ height: '20px' }"
				:cell-style="columnbackgroundStyle"
				:header-cell-style="{ 'text-align': 'center' }"
				header-cell-class-name="table-header"
				:row-class-name="tableRowClassName"
				row-key="aid"
				:tree-props="{ children: 'children' }"
			>
				<el-table-column prop="ranking" label="排名" width="80" align="left">
					<template #default="scope">
						<div
							v-if="scope.row.ranking == 1"
							style="background: #FFEF40;border-radius: 50%;color: #fff;width: 20px;height: 20px;text-align: center;line-height: 20px;display: inline-block;"
						>
							{{ scope.row.ranking }}
						</div>
						<div
							v-else-if="scope.row.ranking == 2"
							style="background: #BFFF40;border-radius: 50%;color: #fff;width: 20px;height: 20px;text-align: center;line-height: 20px;display: inline-block;"
						>
							{{ scope.row.ranking }}
						</div>
						<div
							v-else-if="scope.row.ranking == 3"
							style="background: #40FF70;border-radius: 50%;color: #fff;width: 20px;height: 20px;text-align: center;line-height: 20px;display: inline-block;"
						>
							{{ scope.row.ranking }}
						</div>
						<div
							v-else-if="scope.row.ranking == 4"
							style="background: #40B6FF;border-radius: 50%;color: #fff;width: 20px;height: 20px;text-align: center;line-height: 20px;display: inline-block;"
						>
							{{ scope.row.ranking }}
						</div>
						<div
							v-else-if="scope.row.ranking == 5"
							style="background: #B940FF;border-radius: 50%;color: #fff;width: 20px;height: 20px;text-align: center;line-height: 20px;display: inline-block;"
						>
							{{ scope.row.ranking }}
						</div>
						<div v-else style="width: 20px;height: 20px;text-align: center;line-height: 20px;display: inline-block;">{{ scope.row.ranking }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="姓名" align="left" width="160"></el-table-column>
				<el-table-column prop="department" label="部门" width="120" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" width="130" label="手机号码" align="center"></el-table-column>
				<el-table-column prop="totalCall" label="总拨打次数" align="center" width="100">
					<template #default="scope">
						<span>{{ scope.row.totalCall + scope.row.intercept }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="totalCall" label="拨出次数" align="center" width="80"></el-table-column>
				<el-table-column prop="intercept" label="拦截次数" align="center" width="80"></el-table-column>
				<el-table-column prop="totalPickUp" label="拨通次数" align="center" width="80"></el-table-column>
				<el-table-column prop="appCall" label="APP拨打次数" align="center" width="80"></el-table-column>
				<el-table-column prop="applet" label="小程序拨打次数" align="center" width="80"></el-table-column>
				<el-table-column prop="totalIntention" label="意向次数" align="center" width="80"></el-table-column>
				<el-table-column prop="averageDuration" label="平均通话时长(秒)" align="center" width="130"></el-table-column>
				<el-table-column prop="intentionP" label="接通率" align="center" width="90"></el-table-column>
				<el-table-column prop="totalDuration" label="总通话时长(分)" align="center" width="130">
					<template #default="scope">
						<span>{{ (scope.row.totalDuration / 60).toFixed(2) }}分</span>
					</template>
				</el-table-column>
				<el-table-column prop="carrierCallDuration" label="运营商计费时长(分)" align="center">
					<template #default="scope">
						<span>{{ scope.row.carrierCallDuration }}分</span>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 300, 500, 1000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers } from '../api/index.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { fetchAgent } from '../api/agentIndex';
import { getCallData, download } from '../api/sumIndex';

export default {
	name: 'report',
	data() {
		return {
			query: {
				companyId: '',
				departmentId: '',
				userId: '',
				beginDate: '',
				endDate: '',
				// callCount: '所有',
				pageIndex: 1,
				pageSize: 30,
				sortField: 'totalCall',
				sortBy: 'DESC'
			},
			hasChildren: true,
			fileList: [],
			tableData: [],
			agents: [],
			companys: [],
			departments: [],
			users: [],
			multipleSelection: [],
			delList: [],
			showId: false,
			editVisible: false,
			addVisible: false,
			addButton: false,
			updateButton: false,
			isNotAdmin: true,
			isShow: false,
			isAgent: false,
			isAdmin: false,
			isCompany: false,
			pageTotal: 0,
			form: {
				companyName: '',
				contactName: '',
				phoneNumber: '',
				userId: '',
				status: '未打'
			},
			callSelects: [
				{
					value: '0',
					label: '所有'
				},
				{
					value: '1',
					label: '未拨打'
				}
			],
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			path: process.env.BASE_URL,
			value1: [],
			sortByList: [
				{
					value: 'DESC',
					label: '降序'
				},
				{
					value: 'ASC',
					label: '升序'
				}
			],
			sortFieldList: [
				{
					value: 'totalCall',
					label: '拨出次数'
				},
				{
					value: 'totalPickUp',
					label: '拨通次数'
				},
				{
					value: 'totalIntention',
					label: '意向次数'
				},
				{
					value: 'averageDuration',
					label: '平均通话时长（秒）'
				},
				{
					value: 'intentionP',
					label: '接通率'
				},
				{
					value: 'totalDuration',
					label: '总通话时长（分）'
				},
				{
					value: 'carrierCallDuration',
					label: '运营商计费时长（分）'
				}
			]
		};
	},
	created() {
		let role = localStorage.getItem('ms_role');
		this.getTime();
		if (role === 'admin') {
			this.isAdmin = true;
			this.isAgent = true;
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
		}
		if (role === 'agent') {
			this.isAgent = true;
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
			});
		}

		if (role === 'company') {
			this.isCompany = true;

			let data = {
				companyId: '',
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
		}
		if (role === 'department') {
			let data = {
				companyId: '',
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
		}

		// this.init();
	},
	methods: {
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
			let data1 = {
				currentUserId: localStorage.getItem('user')
			};
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
			});
			let data = {
				companyId: '',
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
			});
			fetchDefaultUsers(localStorage.getItem('user')).then(res => {
				this.users = res.data;
			});
		},
		columnbackgroundStyle({ row, column, rowIndex, columnIndex }) {
			if (this.query.sortField == 'totalCall') {
				if (columnIndex == 5) {
					return 'background:#99b1f9;';
				}
			}
			if (this.query.sortField == 'totalPickUp') {
				if (columnIndex == 7) {
					return 'background:#99b1f9;';
				}
			}
			if (this.query.sortField == 'totalIntention') {
				if (columnIndex == 10) {
					return 'background:#99b1f9;';
				}
			}
			if (this.query.sortField == 'averageDuration') {
				if (columnIndex == 11) {
					return 'background:#99b1f9;';
				}
			}
			if (this.query.sortField == 'intentionP') {
				if (columnIndex == 12) {
					return 'background:#99b1f9;';
				}
			}
			if (this.query.sortField == 'totalDuration') {
				if (columnIndex == 13) {
					return 'background:#99b1f9;';
				}
			}
			if (this.query.sortField == 'carrierCallDuration') {
				if (columnIndex == 14) {
					return 'background:#99b1f9;';
				}
			}
		},

		agentChange(agentId) {
			this.query.agentId = agentId;
			this.query.companyId = '';
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.agentId = agentId;
			this.form.companyId = '';
			this.form.departmentId = '';
			this.form.userId = '';
			if (agentId) {
				let data = {
					agentId: agentId
				};
				fetchCompanyByAgentId(data).then(res => {
					this.companys = res.data;
				});
			}
		},
		companyChange(companyId) {
			this.query.companyId = companyId;
			this.query.departmentId = '';
			this.query.userId = '';
			this.form.companyId = companyId;
			this.form.departmentId = '';
			this.form.userId = '';
			let data = {
				companyId: companyId,
				currentUserId: localStorage.getItem('user')
			};
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.query.pageIndex = 1;
				this.getData();
			});
		},
		departmentChange(departmentId) {
			let data = {
				departmentId: departmentId,
				currentUserId: localStorage.getItem('user')
			};
			fetchUsersByDepartmentId(data).then(res => {
				this.users = res.data;
			});
		},
		tableRowClassName({ row, rowIndex }) {
			console.log(rowIndex, 'rowIndex');
			if (rowIndex === 1) {
				return 'warning-row';
			} else if (rowIndex === 3) {
				return 'success-row';
			}
			return '';
		},
		// 获取数据
		uploadExcel() {
			this.upload = true;
		},
		getTime() {
			let startTime = new Date(new Date());
			let endTime = new Date();
			this.value1.push(startTime);
			this.value1.push(endTime);
		},

		getData() {
			this.loading = true;
			this.tableData = [];
			// if (this.query.callCount == '所有') {
			// 	this.query.callCount = 0;
			// } else {
			// 	this.query.callCount = 1;
			// }
			// 日期
			if (this.value1 === null) {
				this.$message.error('时间不能为空');
				return;
			}
			let beginDate = this.toDateTimeStr(this.value1[0]);
			let endDate = this.toDateTimeStr(this.value1[1]);

			this.query.beginDate = beginDate;
			this.query.endDate = endDate;
			this.query.currentUserId = localStorage.getItem('user');
			getCallData(this.query).then(res => {
				this.loading = false;
				if (res.code === 200) {
					// res.data.list.map((item, index) => {
					// 	item.aid = index;
					// 	this.tableData.push(item);
					// });
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
					// if (this.query.callCount == 0) {
					// 	this.query.callCount = '所有';
					// } else {
					// 	this.query.callCount = '未拨打';
					// }
				} else {
					this.$message.error(res.message);
				}
			});
		},
		toDateTimeStr(date) {
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}

			return y + '-' + m + '-' + d;
		},

		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;

			this.getData();
		},

		handleDownload() {
			if (this.isAgent === true && this.query.companyId === '') {
				this.$message.error('选择查询条件');
				return;
			}
			// 日期
			if (this.value1 === null) {
				this.$message.error('时间不能为空');
				return;
			}
			let beginDate = this.toDateTimeStr(this.value1[0]);
			let endDate = this.toDateTimeStr(this.value1[1]);

			this.query.beginDate = beginDate;
			this.query.endDate = endDate;
			// if (this.query.callCount == '所有') {
			// 	this.query.callCount = 0;
			// } else {
			// 	this.query.callCount = 1;
			// }
			this.query.currentUserId = localStorage.getItem('user');
			download(this.query).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let beginDate = this.query.beginDate;
				let endDate = this.query.endDate;
				let days1 = beginDate.split('-');
				let days2 = endDate.split('-');
				let begin = days1[0] + days1[1] + days1[2];
				let end = days2[0] + days2[1] + days2[2];

				let fileName = '统计报表' + begin + '-' + end + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
				// if (this.query.callCount == 0) {
				// 	this.query.callCount = '所有';
				// } else {
				// 	this.query.callCount = '未拨打';
				// }
			});
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getData();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
