<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" v-model="query.agentId" filterable clearable placeholder="代理商" class="handle-select mr10" style="width: 260px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>

				<el-select size="small" style="width: 260px" v-model="query.classification" filterable clearable placeholder="等级" class="handle-select mr10">
					<el-option v-for="item in classificationList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>

				<el-date-picker size="small" v-model="value1" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				<el-button size="small" type="primary" icon="el-icon-search" style="margin-left: 10px;" @click="handleSearch">搜索</el-button>
				<!-- <el-button size="small" type="primary" icon="el-icon-upload2" style="margin-top: 10px;" @click="handleDownload">导出</el-button> -->
			</div>

			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				v-loading="loading"
				:row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }"
				header-cell-class-name="table-header"
				row-key="aid"

			>
			<el-table-column prop="classification" label="等级" width="80" align="left">
				<template #default="scope">
					{{scope.row.classification}}
				</template>
			</el-table-column>
				<el-table-column prop="companyName" width="360" label="公司" align="left"></el-table-column>
				<el-table-column prop="totalCall" label="总拨打次数" align="center" width="100" >
					<template #default="scope">
						<span>{{ scope.row.totalCall + scope.row.intercept }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="totalCall" label="拨出次数" align="center" width="100" ></el-table-column>
				<el-table-column prop="intercept" label="拦截次数" align="center" width="100" ></el-table-column>
				<el-table-column prop="pickUp" label="拨通次数" align="center" width="100" ></el-table-column>
				<el-table-column prop="totalIntention" label="意向次数" align="center" width="100" ></el-table-column>
				<el-table-column prop="totalIntentionComparison" label="接通率" align="center" width="100" >
					<template #default="scope">
						<span>{{ scope.row.totalPickUpComparison }}%</span>
					</template>
				</el-table-column>
				<el-table-column prop="totalDuration" label="总通话时长(分)" align="center" width="100" >
					<template #default="scope">
						<span>{{ (scope.row.totalDuration / 60).toFixed(2) }}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="classification" label="等级" align="center" width="100" ></el-table-column> -->
				<el-table-column prop="remark" label="备注" align="center" width="300"></el-table-column>
				<el-table-column label="操作" width="134" align="center" fixed="right">
					<template #default="scope">
						<el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex"
					:page-sizes="[100, 300, 500, 1000]"
					:page-size="query.pageSize"
					:total="pageTotal"
					@size-change="handleSizeChange"
					@current-change="handlePageChange"
				></el-pagination>
			</div>
			<el-dialog title="编辑" v-model="editName" width="450px" :close-on-click-modal="false">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px">
					<el-form-item label="等级" prop="classification">
						<el-select size="small" style="width: 100%" v-model="form.classification" filterable clearable placeholder="请选择等级" class="handle-select mr10">
							<el-option v-for="item in classificationList" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注" prop="remark"><el-input size="small" v-model="form.remark"></el-input></el-form-item>
					<el-form-item>
						<el-button size="small" style="margin-left: 50%;" @click="editName = false">取消</el-button>
						<el-button size="small" type="primary" @click="getUpdateCompanyStatistics('form')">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { fetchAgent } from '../api/agentIndex';
import { fetchDepartment } from '../api/index.js';
import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
import { getCompanyStatistics, updateCompanyStatistics } from '../api/sumIndex';

export default {
	name: 'report',
	data() {
		return {
			query: {
				agentId: '',
				userId: '',
				beginDate: '',
				endDate: '',
				pageIndex: 1,
				pageSize: 30,
				classification: ''
			},
			hasChildren: true,
			fileList: [],
			tableData: [],
			agents: [],
			companys: [],
			departments: [],
			users: [],
			multipleSelection: [],
			delList: [],
			showId: false,
			editVisible: false,
			addVisible: false,
			addButton: false,
			updateButton: false,
			isNotAdmin: true,
			isShow: false,
			isAgent: false,
			isAdmin: false,
			isCompany: false,
			editName: false,
			pageTotal: 0,
			form: {
				classification: '',
				remark: '',
				companyId: ''
			},
			classificationList: [
				{
					value: 'A',
					label: 'A级'
				},
				{
					value: 'B',
					label: 'B级'
				},
				{
					value: 'C',
					label: 'C级'
				},
				{
					value: 'D',
					label: 'D级'
				}
			],
			idx: -1,
			id: -1,
			loading: false,
			token: { Authorization: localStorage.getItem('token') },
			path: process.env.BASE_URL,
			value1: []
		};
	},

	created() {
		let role = localStorage.getItem('ms_role');
		console.log(role, '角色');
		this.getTime();
		if (role === 'admin') {
			this.isAdmin = true;
			this.isAgent = true;
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});
		}
		if (role === 'agent') {
			this.isAgent = true;
			let data1 ={
				currentUserId:localStorage.getItem('user')
			}
			fetchDefaultCompanies(data1).then(res => {
				this.companys = res.data;
				this.getData();
			});
		}

		if (role === 'company') {
			this.isCompany = true;
			let data ={
				companyId:'',
				currentUserId:localStorage.getItem('user')
			}
			fetchDepartment(data).then(res => {
				this.departments = res.data;
				this.getData();
			});
		
		}
	},
	methods: {
	
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});

			fetchDefaultUsers(localStorage.getItem('user')).then(res => {
				this.users = res.data;
			});
		},
		
		departmentChange(departmentId) {
			let data ={
				departmentId:departmentId,
				currentUserId:localStorage.getItem('user')
			}
			fetchUsersByDepartmentId(data).then(res => {
				this.users = res.data;
			});
		},
		// 获取数据
		uploadExcel() {
			this.upload = true;
		},
		getTime() {
			let startTime = new Date(new Date());
			let endTime = new Date();
			this.value1.push(startTime);
			this.value1.push(endTime);
		},
		getData() {
			this.loading = true;
			this.tableData = [];

			// 日期
			if (this.value1 === null) {
				this.$message.error('时间不能为空');
				return;
			}
			let beginDate = this.toDateTimeStr(this.value1[0]);
			let endDate = this.toDateTimeStr(this.value1[1]);

			this.query.beginDate = beginDate;
			this.query.endDate = endDate;
			this.query.userId = localStorage.getItem('user');
			getCompanyStatistics(this.query).then(res => {
				this.loading = false;
				if (res.code === 200) {
					
					this.tableData = res.data.list;
					this.pageTotal = res.data.total;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		toDateTimeStr(date) {
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			if (m < 10) {
				m = '0' + m;
			}
			if (d < 10) {
				d = '0' + d;
			}

			return y + '-' + m + '-' + d;
		},

		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},

		handleDownload() {
			if (this.isAgent === true && this.query.companyId === '') {
				this.$message.error('选择查询条件');
				return;
			}
			// 日期
			if (this.value1 === null) {
				this.$message.error('时间不能为空');
				return;
			}
			let beginDate = this.toDateTimeStr(this.value1[0]);
			let endDate = this.toDateTimeStr(this.value1[1]);

			this.query.beginDate = beginDate;
			this.query.endDate = endDate;
			this.query.currentUserId = localStorage.getItem('user');
			download(this.query).then(res => {
				const blob = new Blob([res], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
				});
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				let beginDate = this.query.beginDate;
				let endDate = this.query.endDate;
				let days1 = beginDate.split('-');
				let days2 = endDate.split('-');
				let begin = days1[0] + days1[1] + days1[2];
				let end = days2[0] + days2[1] + days2[2];

				let fileName = '统计报表' + begin + '-' + end + '.xlsx';
				downloadElement.download = fileName;
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
			});
		},
		// 编辑操作
		handleEdit(row) {
			this.form.companyId = row.companyId;
			this.form.classification = row.classification;
			this.form.remark = row.remark;
			this.editName = true;
		},
		getUpdateCompanyStatistics() {
			updateCompanyStatistics(this.form).then(res => {
				if (res.code === 200) {
					this.$message.success('修改成功');
					this.editName = false;
					this.getData();
				} else {
					this.$message.error(res.message);
				}
				this.editName = false;
			});
		},

		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		handleSizeChange(val) {
			this.query.pageSize = val;
			this.getData();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 120px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
