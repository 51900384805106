import request from '../utils/request';
import qs from "qs";





//获取oem信息
export const getOemInfo = url => {
	return request.get('/api/oem/getOemInfo?url=' + url)
};
//oem信息详情
export const OemInfo = userId => {
	return request.get('/api/oem/info?userId=' + userId)
};
//添加oem
export const oemAdd = param => {
	return request.post('/api/oem/add', qs.stringify(param))
};
//修改oem
export const updateOemInfo = param => {
	return request.post('/api/oem/updateOemInfo', qs.stringify(param))
};

//获取首页轮播图
export const loginSlideshowList = param => {
	return request.post('/api/oem/loginSlideshowList', qs.stringify(param))
};
//添加首页轮播图
export const addLoginSlideshow = param => {
	return request.post('/api/oem/addLoginSlideshow', qs.stringify(param))
};

//删除首页轮播图
export const deleteLoginSlideshow = param => {
	return request.post('/api/oem/deleteLoginSlideshow', qs.stringify(param))
};

//修改登录页轮播图排序
export const updateLoginSlideshow = param => {
	return request.post('/api/oem/updateLoginSlideshow', qs.stringify(param))
};